import { inject } from '@angular/core';
import {
  HttpInterceptorFn,
  HttpRequest,
  HttpHandlerFn,
  HttpXsrfTokenExtractor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, switchMap, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

const XRSF_HEADER_NAME = 'X-XSRF-TOKEN';

export const httpXsrfInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn) => {
  const tokenService = inject(HttpXsrfTokenExtractor);
  const authService = inject(AuthService);

  // Skip if the request is GET or HEAD
  if (req.method === 'GET' || req.method === 'HEAD') {
    return next(req);
  }

  // Get the initial CSRF token
  const token = tokenService.getToken();

  // Add CSRF token to the request headers if not already present
  if (token !== null && !req.headers.has(XRSF_HEADER_NAME)) {
    req = req.clone({ headers: req.headers.set(XRSF_HEADER_NAME, token) });
  }

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      const isCsrfError = error.status === 419 && error.error?.message === 'CSRF token mismatch.';

      if (!isCsrfError) {
        return throwError(() => error);
      }

      // Refresh the CSRF token by making a GET request to the CSRF endpoint
      return authService.refreshCsrfToken().pipe(
        switchMap(() => {
          // Retrieve the new CSRF token
          const newToken = tokenService.getToken();

          if (!newToken) {
            return next(req); // Proceed without modifying the request if no token is present
          }

          // Clone the original request and attach the new CSRF token
          const clonedRequest = req.clone({
            headers: req.headers.set(XRSF_HEADER_NAME, newToken)
          });

          // Retry the original request with the new token
          return next(clonedRequest);
        }),
        catchError(() => throwError(() => error))
      );
    })
  );
};
