import { HttpInterceptorFn, HttpRequest, HttpHandlerFn, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';

export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
  const authService = inject(AuthService);
  const userToken = authService.userTokenValue;

  let headers: { [key: string]: string } = {
    'enctype': 'multipart/form-data',
    'Accept': 'application/json',
    'realm': 'default',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, PATCH',
    'Access-Control-Allow-Headers': 'Authorization, Origin, Accept, Content-Type, X-CSRF-Token',
    'mode': 'no-cors'
  };

  if (userToken) {
    headers['Authorization'] = 'Bearer ' + userToken;
  }

  req = req.clone({
    setHeaders: headers
  });
  return next(req);
};
