import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHandlerService, UserDTO, convertToFormData, interceptorsToken, ActivityIndicatorType, StorageService, MART_USER_KEY, MART_TOKEN_KEY, LoginResponseDTO } from 'library-explorer';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authenticatedUser$ = new BehaviorSubject<UserDTO | false>(false);
  private userToken$ = new BehaviorSubject<string | null>(null);

  constructor(private httpWrapper: HttpHandlerService, private storageService: StorageService) { }

  get authUser(): Observable<UserDTO | false> {
    return this.authenticatedUser$.asObservable();
  }

  updateAuthUser(user: UserDTO): void {
    this.storageService.setStorageValue(MART_USER_KEY, JSON.stringify(user));
    this.authenticatedUser$.next(user);
  }

  get userTokenValue(): string | null {
    return this.userToken$.value;
  }

  updateUserToken(token: string): void {
    this.storageService.setStorageValue(MART_TOKEN_KEY, token);
    this.userToken$.next(token);
  }

  async init() {
    const authUserToken = await this.storageService.check4Value(MART_TOKEN_KEY);
    const authUser = await this.storageService.check4Value(MART_USER_KEY);

    if (!authUser || !authUserToken) return;

    this.updateUserToken(authUserToken);
    this.updateAuthUser(JSON.parse(authUser));
  }

  login(formDataJson: any): Observable<UserDTO> {
    const formData = convertToFormData(formDataJson);
    const headers: any = new HttpHeaders().set(interceptorsToken.ACTIVITY_INDICATOR_TYPE, ActivityIndicatorType.ProgressBar);

    return this.httpWrapper.postRequest<LoginResponseDTO>('login', formData, undefined, headers, undefined, true)
      .pipe(
        tap((res: LoginResponseDTO) => this.authenticateUser(res)),
        map((res: LoginResponseDTO) => res.user)
      );
  }

  private authenticateUser(loginResponse: LoginResponseDTO) {
    this.updateUserToken(loginResponse.token);
    this.updateAuthUser(loginResponse.user);
  }

  refreshCsrfToken() {
    return this.httpWrapper.getThirdPartyRequest(`/api/sanctum/csrf-cookie`,
      { withCredentials: true }
    );
  }
}
