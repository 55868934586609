import { Routes } from '@angular/router';
import { mainMenuRoutes } from './pages/menu/menu.routes';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'menu',
    pathMatch: 'full'
  },
  {
    path: 'signin',
    loadComponent: () => import('./pages/auth/signin/signin.page').then(m => m.SigninPage),
  },
  {
    path: 'menu',
    children: mainMenuRoutes,
    loadComponent: () => import('./pages/menu/menu.page').then(m => m.MenuPage),
  },
  {
    path: 'dashboard',
    loadComponent: () => import('./pages/dashboard/dashboard.page').then(m => m.DashboardPage)
  },
  {
    path: 'products',
    redirectTo: 'menu/products'
  },
  {
    path: 'products/view/:slug',
    redirectTo: 'menu/products/view/:slug'
  },
  {
    path: 'products/add',
    redirectTo: 'menu/products/add'
  },
  {
    path: 'categories',
    redirectTo: 'menu/categories'
  },
  {
    path: 'categories/add',
    redirectTo: 'menu/categories/add'
  },
  {
    path: 'categories/view/:slug',
    redirectTo: 'menu/categories/view/:slug'
  },
  {
    path: 'transactions',
    redirectTo: 'menu/transactions'
  },
  {
    path: 'transactions/view',
    redirectTo: 'menu/transactions/view'
  },
  {
    path: 'users',
    redirectTo: 'menu/users'
  },
  {
    path: 'users/view',
    redirectTo: 'menu/users/view'
  },
  {
    path: 'my-account',
    redirectTo: 'menu/my-account'
  },
  {
    path: 'banners',
    redirectTo: 'menu/banners'
  },
  {
    path: 'banners/view/:id',
    redirectTo: 'menu/banners/view/:id'
  },
  {
    path: 'banners/add',
    redirectTo: 'menu/banners/add'
  },
  {
    path: 'code-generator',
    redirectTo: 'menu/code-generator'
  },
  {
    path: 'codes/:slug',
    redirectTo: 'menu/codes/:slug'
  },
];
