import { Routes } from '@angular/router';

export const mainMenuRoutes: Routes = [
  { path: '', redirectTo: 'banners', pathMatch: 'full' },
  {
    path: 'dashboard',
    loadComponent: () => import('../dashboard/dashboard.page').then(m => m.DashboardPage)
  },
  {
    path: 'products',
    loadComponent: () =>
      import('../listings/index/index.page').then((m) => m.IndexPage),
  },
  {
    path: 'products/view/:slug',
    loadComponent: () =>
      import('../listings/view-product/view-product.page').then((m) => m.ViewProductPage),
  },
  {
    path: 'products/add',
    loadComponent: () =>
      import('../listings/add/add.page').then((m) => m.AddPage),
  },
  {
    path: 'categories',
    loadComponent: () =>
      import('../categories/index/index.page').then((m) => m.IndexPage),
  },
  {
    path: 'categories/add',
    loadComponent: () =>
      import('../categories/add-category/add-category.page').then((m) => m.AddCategoryPage),
  },
  {
    path: 'categories/view/:slug',
    loadComponent: () =>
      import('../categories/view-category/view-category.page').then((m) => m.ViewCategoryPage),
  },
  {
    path: 'transactions',
    loadComponent: () =>
      import('../transactions/index/index.page').then((m) => m.IndexPage),
  },
  {
    path: 'transactions/view',
    loadComponent: () =>
      import('../transactions/view-transactions/view-transactions.page').then((m) => m.ViewTransactionsPage),
  },
  {
    path: 'users',
    loadComponent: () =>
      import('../users/index/index.page').then((m) => m.IndexPage),
  },
  {
    path: 'users/view',
    loadComponent: () =>
      import('../users/view-user/view-user.page').then((m) => m.ViewUserPage),
  },
  {
    path: 'my-account',
    loadComponent: () =>
      import('../my-account/my-account.page').then((m) => m.MyAccountPage),
  },
  {
    path: 'banners',
    loadComponent: () => import('../banners/index/index.page').then(m => m.IndexPage)
  },
  {
    path: 'banners/view/:id',
    loadComponent: () => import('../banners/view/view.page').then(m => m.ViewPage)
  },
  {
    path: 'banners/add',
    loadComponent: () => import('../banners/add-banner/add-banner.page').then(m => m.AddBannerPage)
  },
  {
    path: 'codes/:slug',
    loadComponent: () => import('../listings/codes/codes.page').then(m => m.CodesPage)
  }
];
