import { HttpInterceptorFn } from '@angular/common/http';
import { interceptorsToken } from 'library-explorer';
import { environment } from 'src/environments/environment';

export const apiUrlInterceptor: HttpInterceptorFn = (req, next) => {
  const thirdPartyHeader = interceptorsToken.THIRDPARTY_URL_ENABLED;
  const adminRequestHeader = interceptorsToken.IS_ADMIN_REQUEST;

  // Check for the third-party URL header and modify the request if it exists
  if (req.headers.has(thirdPartyHeader)) {
    const thirdPartyReq = req.clone({
      headers: req.headers.delete(thirdPartyHeader)
    });
    return next(thirdPartyReq);
  }

  // Determine the appropriate base URL based on the request headers
  const baseUrl = req.headers.has(adminRequestHeader) ? environment.adminApiUrl : environment.apiUrl;

  // Clone the request with the updated URL
  const apiReq = req.clone({
    url: `${baseUrl}/${req.url}`
  });

  // Continue with the modified request
  return next(apiReq);
};
