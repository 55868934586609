import { ActivityIndicatorComponent, ScreenHelperService } from 'library-explorer';
import { Component, HostListener } from '@angular/core';
import { IonApp, IonRouterOutlet, Platform } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import * as ionIcons from 'ionicons/icons';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonApp, IonRouterOutlet, ActivityIndicatorComponent],
})
export class AppComponent {
  constructor(private platform: Platform, private screenHelperService: ScreenHelperService) {
    this.initializeApp();
  }

  initializeApp() {
    addIcons(ionIcons);
    this.screenHelperService.onResize(this.platform.width());
  }

  // Screen Size Handler
  @HostListener('window:resize', ['$event'])
  private onResize(event: any) {
    this.screenHelperService.onResize(event.target.innerWidth);
  }
}
